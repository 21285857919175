import { WSTableColumn } from "@wingspanhq/fe-component-library";
import { ReportsColumn } from "../../types";
// import { IReceivableAgingReportResponse } from "@wingspanhq/payments/dist/interfaces/api/reports";
import { formatTableValue } from "../../utils/formatTableValue";
import { IReceivableAgingReportResponse } from "./mocksTypes";

export const selectorTableColumns = (
  columns: ReportsColumn<IReceivableAgingReportResponse>[],
  data: IReceivableAgingReportResponse[]
) => {
  return [
    ...columns.map(
      column =>
        ({
          config: {
            header: { text: column?.label || "-" }
          },
          renderFunction: rowData =>
            formatTableValue(column?.getTableCell(rowData.data))
        } as WSTableColumn<IReceivableAgingReportResponse>)
    )
  ];
};
