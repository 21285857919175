import { IStatementResponse } from "../../../services/api/banking/statements/types";

export const sortData = (config: Record<string, "asc" | "desc">) => (
  a: IStatementResponse,
  b: IStatementResponse
) => {
  const sortByParam = Object.getOwnPropertyNames(
    config
  )[0] as keyof IStatementResponse;

  if (!sortByParam) return 0;

  const direction = config[sortByParam] === "asc" ? 1 : -1;

  const valueA = a[sortByParam];
  const valueB = b[sortByParam];

  if (typeof valueA === "number" && typeof valueB === "number") {
    return (valueA - valueB) * direction;
  }

  if (valueA instanceof Date && valueB instanceof Date) {
    return (valueA.getTime() - valueB.getTime()) * direction;
  }

  const isDateA = !isNaN(Date.parse(`${valueA}`));
  const isDateB = !isNaN(Date.parse(`${valueB}`));

  if (isDateA && isDateB) {
    const dateA = new Date(valueA as string).getTime();
    const dateB = new Date(valueB as string).getTime();
    return (dateA - dateB) * direction;
  }

  return (
    `${valueA}`.toLowerCase().localeCompare(`${valueB}`.toLowerCase()) *
    direction
  );
};
