import {
  useWSModal,
  WSActions,
  WSDivider,
  WSElement,
  WSText
} from "@wingspanhq/fe-component-library";
import { useCreateRequirement } from "../../../../query/eligibilityRequirements/mutation/useCreateRequirement";
import {
  DefinitionDataSourceType,
  IRequirementDefinitionResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import { useUserId } from "../../../../query/hooks/helpers";

import styles from "./styles.module.scss";
import { useAddRequirementToEngagement } from "../../../../query/engagements/mutations/useAddRequirementToEngagement";
import { CreateRequirementDefinitionFormValues } from "../../types";

interface ConfirmCreateRequirementModalProps {
  onClose: () => void;
  onSuccess: (requirement: IRequirementDefinitionResponse) => void;
  templateId: string;
  formValues: CreateRequirementDefinitionFormValues;
  engagementId?: string;
}

const ConfirmCreateRequirementModal: React.FC<ConfirmCreateRequirementModalProps> = ({
  onClose,
  templateId,
  formValues,
  engagementId,
  onSuccess
}) => {
  const userId = useUserId();
  const [createRequirement, createRequirementMeta] = useCreateRequirement();
  const [
    addRequirementToEngagement,
    addRequirementToEngagementMeta
  ] = useAddRequirementToEngagement(engagementId as string);
  return (
    <>
      <WSDivider mb="XL" />

      <WSElement className={styles.confirmMessage} p="M">
        <WSText.ParagraphXs weight="medium" mb="XS">
          Confirm requirement creation
        </WSText.ParagraphXs>

        <WSText.ParagraphXs weight="book">
          Once a requirement is created, it cannot be edited. If the document
          and signature fields need to be changed, a new version of the file
          will need to be uploaded and configured. Existing requirements can be
          previewed, attached or removed from engagement at any time.
        </WSText.ParagraphXs>
      </WSElement>

      <WSDivider my="XL" />

      <WSActions
        buttons={[
          {
            label: "Create requirement",
            kind: "Primary",
            name: "createRequirement",
            loading:
              createRequirementMeta.isLoading ||
              addRequirementToEngagementMeta.isLoading,
            onClick: async () => {
              await createRequirement(
                {
                  clientId: userId,
                  dataSourceType:
                    DefinitionDataSourceType.SignedDocumentTemplate,
                  dataSourceId: templateId,
                  name: formValues.name,
                  description: formValues.description,
                  requirementType: formValues.type as RequirementType
                },
                {
                  onSuccess: async (
                    requirement: IRequirementDefinitionResponse
                  ) => {
                    if (engagementId) {
                      // update engagement with new requirement
                      await addRequirementToEngagement(
                        {
                          requirementId: requirement.requirementDefinitionId
                        },
                        {
                          onSuccess: () => {
                            onSuccess(requirement);
                          }
                        }
                      );
                    } else {
                      onSuccess(requirement);
                    }
                  }
                }
              );
              onClose();
            }
          },
          {
            label: "Cancel",
            kind: "Secondary",
            onClick: onClose
          }
        ]}
        alignment="fill"
      />
    </>
  );
};

export function useConfirmCreateRequirementModal() {
  return useWSModal(ConfirmCreateRequirementModal, {
    title: "Create requirement",
    size: "S"
  });
}
