import { CellsConfig, RequirementTableCallback } from "../../../../types";
import {
  IMemberClientRequirementResponse,
  IRequirementResponse
} from "@wingspanhq/payments/dist/interfaces";
import { WSTableCell } from "@wingspanhq/fe-component-library";
import React from "react";
import { SHARED_FILE_REQUIREMENT_EVENT } from "../utils";
import { RequirementEmbeddedStatus } from "@wingspanhq/payments/dist/interfaces/requirement";
import { useDownloadMemberDocument } from "../../../../../../query/files/mutations";
import {
  WSTableAction,
  WSTableRowMenuAction
} from "@wingspanhq/fe-component-library/dist/lib/components/WSTable/types";
import { getDocumentFromCache } from "../helpers/getDocumentFromCache";
import { useSignSignedDocument } from "../../../../../../query/signedDocuments/mutations/useSignSignedDocument";
import { ISignedDocumentResponse } from "@wingspanhq/signed-documents/dist/lib/interfaces";
import { useUserId } from "../../../../../../query/hooks/helpers";
import { selectorRequirementHumanReadableType } from "../../../../selectors/selectorRequirementHumanReadableType";
import { selectorIsRequirementReadyToSignAsPayee } from "../../../../selectors/selectorIsRequirementReadyToSignAsPayee";

export const usePayerSharedFileRequirementConfig = (
  callback: RequirementTableCallback
): CellsConfig<IRequirementResponse> => {
  const userId = useUserId();
  const [signDocument] = useSignSignedDocument();
  const [
    downloadPDFAsPayee,
    downloadPDFAsPayeeMeta
  ] = useDownloadMemberDocument();

  const handleRequirementDownload = async (
    requirement: IRequirementResponse
  ) => {
    await downloadPDFAsPayee(
      {
        documentId: requirement.dataSourceId!
      },
      {
        onSuccess() {
          callback(SHARED_FILE_REQUIREMENT_EVENT.DownloadAsPayee, requirement);
        }
      }
    );
  };

  const handleRequirementSign = async (
    requirement: IRequirementResponse,
    document: ISignedDocumentResponse
  ) => {
    await signDocument(document, {
      onSuccess() {
        callback(SHARED_FILE_REQUIREMENT_EVENT.SignAsPayee, requirement);
      }
    });
  };

  return {
    nameCell: requirement => {
      const document = getDocumentFromCache(requirement);

      if (requirement.dataSourceId && !document) {
        return (
          <WSTableCell
            text="--"
            secondaryText="error loading"
            secondaryTextColor="amber400"
          />
        );
      }

      return <WSTableCell text={document?.title} />;
    },
    typeCell: requirement => {
      return (
        <WSTableCell
          icon="pen"
          text={selectorRequirementHumanReadableType(
            requirement.requirementType
          )}
        />
      );
    },
    statusCell: requirement => {
      const document = getDocumentFromCache(requirement);

      if (requirement.dataSourceId && !document) {
        return (
          <WSTableCell
            text="--"
            secondaryText="error loading"
            secondaryTextColor="amber400"
          />
        );
      }

      return (
        <WSTableCell
          pill={
            requirement.dataSourceStatus === RequirementEmbeddedStatus.Complete
              ? {
                  theme: "success",
                  text: "Complete",
                  icon: true
                }
              : {
                  theme: "warning",
                  text: "Pending",
                  icon: true
                }
          }
          secondaryText={
            requirement.dataSourceStatus === RequirementEmbeddedStatus.Complete
              ? ""
              : selectorIsRequirementReadyToSignAsPayee(requirement, document)
              ? "Your signature required"
              : "Awaiting client signature"
          }
        />
      );
    },
    rowMenuActions: requirement => {
      const actions: WSTableRowMenuAction<
        IMemberClientRequirementResponse
      >[] = [];

      const document = getDocumentFromCache(requirement);

      if (requirement?.dataSourceId) {
        actions.push({
          name: "DownloadDocument",
          onAsyncClick: async () => {
            await handleRequirementDownload(requirement);
          },
          label: "Download PDF",
          icon: "download"
        });
      }

      if (selectorIsRequirementReadyToSignAsPayee(requirement, document)) {
        actions.push({
          name: "SignDocument",
          onAsyncClick: async () => {
            await handleRequirementSign(requirement, document!);
          },
          label: "Sign",
          icon: "edit-line"
        });
      }

      // hide actions if only sign is available
      return requirement?.dataSourceId ? actions : [];
    },
    rowActions: requirement => {
      const actions: WSTableAction[] = [];

      const document = getDocumentFromCache(requirement);

      if (selectorIsRequirementReadyToSignAsPayee(requirement, document)) {
        actions.push({
          name: "SignDocument",
          onAsyncClick: async () => {
            await handleRequirementSign(requirement, document!);
          },
          text: "Start",
          kind: "Link",
          hideOn: []
        });
      }

      return actions;
    }
  };
};
