import { WSControl, WSInfoBox, WSList } from "@wingspanhq/fe-component-library";
import React from "react";
import { operations } from "../../../services/api/onboarding/types";
import { WS_LINKS } from "../../../types/wsLinks";

type ConfirmationDisclosuresProps = {
  consent: boolean;
  setConsent: (value: boolean) => void;
  verificationLevel?: operations["getVerificationMissingData"]["parameters"]["path"]["verificationLevel"];
};

export const ConfirmationDisclosures: React.FC<ConfirmationDisclosuresProps> = ({
  consent,
  setConsent,
  verificationLevel
}) => {
  if (verificationLevel === "Tax") {
    return (
      <WSList gap="2XL">
        <WSInfoBox>
          This information will be used to customize your Wingspan experience.
          Once you continue, you cannot return to this step and the information
          cannot be changed. Please verify all information provided is accurate.
        </WSInfoBox>

        <WSControl
          type="checkbox"
          value={consent}
          onChange={setConsent}
          size="S"
          label="By checking this box, I confirm that all information is accurate. I agree to the processing of my information for identity verification as detailed in the Privacy Policy."
        />
      </WSList>
    );
  }

  return (
    <WSList gap="2XL">
      <WSInfoBox>
        <p>
          This information will be used to customize your Wingspan experience.
          Once you continue, you cannot return to this step and the information
          cannot be changed. Please verify all information provided is accurate.
        </p>
        <br />
        <p>
          By clicking continue you agree that you have read, understood, and
          agree to the following:
        </p>
        <br />

        <ol>
          <li>
            <a
              href={WS_LINKS.depositAccountTermsConditions}
              target="_blank"
              rel="noreferrer"
            >
              Business Deposit Account Agreement
            </a>
            : By opening an account with us, you agree to the terms outlined in
            our Wingspan Business Deposit Account Agreement. Please review this
            document carefully.
          </li>

          {/*<li>
            <a
              href={WS_LINKS.debitCardHolderAgreement}
              target="_blank"
              rel="noreferrer"
            >
              Debit Card Holder Agreement
            </a>
            : Use of our debit card is governed by the Business VISA Debit
            Cardholder Agreement.
          </li>
          <li>
            <a
              href={WS_LINKS.cashBackTermsAndConditions}
              target="_blank"
              rel="noreferrer"
            >
              Wingspan Cash Back Terms & Conditions
            </a>
            : Our cash back program is subject to specific terms and conditions.
          </li>
          */}
          <li>
            <a
              href={WS_LINKS.wingspanPrivacyPolicyAcceptance}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Notice
            </a>
            : We value your privacy. Our Lead Privacy Notice details how we
            collect, use, and protect your personal information.
          </li>
          <li>
            <a
              href={WS_LINKS.electronicDisclosureAndConsent}
              target="_blank"
              rel="noreferrer"
            >
              Electronic Disclosure and Consent
            </a>
            : By opening an account, you agree to receive all communications
            from us electronically. This includes important disclosures,
            notices, and other information related to your account.
          </li>
          <li>
            <a
              href={WS_LINKS.crossBorderPaymentServicesAgreement}
              target="_blank"
              rel="noreferrer"
            >
              Cross-Border Payment Services Agreement
            </a>
            : Our services power international payments. By using our
            cross-border payment capabilities, you agree to the Currency Cloud
            Terms of Use, which govern these international transactions.
          </li>
        </ol>
        <br />
        <p>
          Wingspan is not a financial services technology company and is not a
          bank. Banking services provided by Lead Bank, member FDIC.
        </p>
        <br />
        <p>
          The responsibility for ensuring full tax compliance, including any
          additional documentation that may be required in specific local
          jurisdictions, rests with the payer and the contractor. We recommend
          that both clients and contractors consult with qualified tax
          professionals in their respective jurisdictions to ensure they meet
          all local tax obligations and reporting requirements. Wingspan's role
          is to facilitate the collection of standard tax information, but we
          cannot guarantee that this covers every possible local requirement in
          all cases.
        </p>
      </WSInfoBox>
      <WSControl
        type="checkbox"
        value={consent}
        onChange={setConsent}
        size="S"
        label="By checking this box, I confirm that all information is accurate. I agree to the processing of my information for identity verification as detailed in the Privacy Policy. I have read, and agree to all the above-mentioned documents and their contents."
      />
    </WSList>
  );
};
