import React, { useEffect } from "react";
import {
  WSAlert,
  WSControlGroup,
  WSDropFileInput,
  WSElement,
  WSFlexBox,
  WSForm,
  WSIcon,
  WSList,
  WSText
} from "@wingspanhq/fe-component-library";
import styles from "../../../components/CreateRequirementDefinitionForm/styles.module.scss";
import { useFormContext } from "react-hook-form";

export const CreateSignaturePartialForm: React.FC = () => {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    register("eSignatureFile");
    register("requiredSignatures");
  }, []);

  return (
    <WSList gap="XL">
      <WSAlert theme="info" title="About e-Signature requirements">
        Contractor(s) assigned with this requirement will be asked to e-sign the
        document uploaded here.
      </WSAlert>

      <WSForm.Value name="eSignatureFile">
        {(file?: File) =>
          file ? (
            <>
              <WSElement p="XL" className={styles.selectedFile}>
                <WSFlexBox.CenterY wrap="nowrap">
                  <WSText className={styles.selectedFileName} singleLine>
                    {file.name}
                  </WSText>
                  <WSFlexBox>
                    <WSIcon
                      block
                      color="gray500"
                      size="M"
                      mx="M"
                      name="exit"
                      onClick={() => {
                        setValue("eSignatureFile", undefined);
                      }}
                    />
                  </WSFlexBox>
                </WSFlexBox.CenterY>
              </WSElement>
              <WSForm.Field
                name="requiredSignatures"
                label="Required signatures"
                component={WSControlGroup}
                componentProps={{
                  required: true,
                  type: "radio",
                  options: [
                    {
                      value: "collaborator",
                      label: "Contractor signature required only"
                    },
                    {
                      value: "mutual",
                      label:
                        "Both contractor and company signatures are required"
                    }
                  ]
                }}
              />
            </>
          ) : (
            <WSElement>
              <WSText.ParagraphSm mb="S" color="gray500">
                Upload e-Signature file *
              </WSText.ParagraphSm>
              <WSDropFileInput
                name="dropFileInput"
                buttonText="Browse files"
                buttonProps={{
                  type: "button"
                }}
                accept=".pdf"
                onDropAccepted={accepted => {
                  setValue("eSignatureFile", accepted[0]);
                }}
              />
            </WSElement>
          )
        }
      </WSForm.Value>
    </WSList>
  );
};
