import {
  AdditionalDataResourceType,
  IAdditionalData,
  ICollaboratorsPayoutsSummaryReportResponse,
  ICollaboratorsReportResponse,
  IPayableAgingReportResponse,
  WSDataTypes
} from "@wingspanhq/payments/dist/interfaces";
import { formatReportsValue } from "./formatReportsValue";
import { ReportsColumn } from "../types";
import { PayrollTableItem } from "../routes/RoutePayrollLineItemsReport/columns";
import { LineItemAgingTableItem } from "../routes/RouteOpenLineItemAgingReport/columns";
import { IReceivableAgingReportResponse } from "../routes/RouteOpenReceivableAging/mocksTypes";

/// Line item custom fields on line items
type LineItemRow = PayrollTableItem | LineItemAgingTableItem;

// Collaborator custom fields on root object
type CollaboratorRow =
  | IPayableAgingReportResponse
  | IReceivableAgingReportResponse
  | ICollaboratorsPayoutsSummaryReportResponse
  | ICollaboratorsReportResponse;

// Collaborator custom fields on invoice objects
type InvoiceRow = PayrollTableItem;

// Row types that will have custom field data
type CustomFieldRow = CollaboratorRow | InvoiceRow | LineItemRow;

// Options when invoking selectorColumnsWithAdditionalData
type SelectorColumnOptions = {
  groupName?: string;
  prefixGroupName?: boolean;
  preselected?: boolean;
};

// Specify data type for row as T
export const selectorColumnsWithAdditionalData = <T extends CustomFieldRow>(
  baseColumns: ReportsColumn<T>[],
  additionalData: IAdditionalData[],
  options: SelectorColumnOptions = {}
) => [
  ...baseColumns,
  ...(additionalData || []).map(addData => {
    // Set default options
    options = {
      groupName: "Custom Fields",
      prefixGroupName: true,
      preselected: true,
      ...options
    };
    const isCollaboratorCustomField =
      addData.resourceType === AdditionalDataResourceType.Collaborator;
    let groupName = options.groupName;
    if (options.prefixGroupName) {
      const prefix = isCollaboratorCustomField ? "Contractor" : "Line Item";
      groupName = `${prefix} ${groupName}`;
    }

    return {
      value: `customField.${addData.name}`,
      label: `Custom Field ${addData.name}`,
      preselected: options.preselected,
      group: groupName,
      getTableCell(data: T) {
        let value;
        if (isCollaboratorCustomField) {
          if (isCollaboratorRow(data)) {
            value = (data.labels ?? {})[addData.key];
          } else if (isInvoiceRow(data)) {
            value = (data.invoice?.labels ?? {})[addData.key];
          }
        }
        if (isLineItemRow(data) && !isCollaboratorCustomField) {
          value = data.lineItem.labels[addData.key];
        }

        return addData.type === WSDataTypes.Boolean
          ? value === "true"
            ? "Yes"
            : "No"
          : formatReportsValue(value) || "-";
      }
    } as ReportsColumn<T>;
  })
];

function isLineItemRow(data: CustomFieldRow): data is LineItemRow {
  return "lineItem" in data;
}

function isCollaboratorRow(data: CustomFieldRow): data is CollaboratorRow {
  return "labels" in data;
}

function isInvoiceRow(data: CustomFieldRow): data is InvoiceRow {
  if ("invoice" in data) {
    const invoice = data.invoice;
    return "labels" in invoice;
  }
  return false;
}
