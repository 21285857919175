import React from "react";
import {
  WSCard,
  WSGrid,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { useHistory } from "react-router-dom";
import { useAuthorizedScopeGroups } from "../../../../shared/utils/teamUtils";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";

export const RouteDashboardReports: React.FC = () => {
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  const {
    hasPaymentsScope,
    hasCollaboratorsScope
  } = useAuthorizedScopeGroups();

  return (
    <WSPage title="Reports">
      {hasPaymentsScope ? (
        <>
          <WSText.Heading4 mb="XL">Payables Reports</WSText.Heading4>

          <WSGrid>
            <WSGrid.Item span={{ s: "6", l: "5" }}>
              <WSCard
                data-testid="payrollLineItemsReport"
                title="Payroll Line Items"
                content="All line items associated with a completed payroll run including invoice data, payment dates, line item details, amounts, and payout statuses."
                size="XS"
                primaryAction={{
                  label: "Open report",
                  onClick() {
                    history.push("/member/reports/payroll");
                  }
                }}
              />
            </WSGrid.Item>
            <WSGrid.Item span={{ s: "6", l: "5" }}>
              <WSCard
                data-testid="openPayableAgingReport"
                title="Open Payable Aging"
                content="Accounts payable aging summary of open payables grouped in either 7-day or 30-day due date increments."
                size="XS"
                primaryAction={{
                  label: "Open report",
                  onClick() {
                    history.push("/member/reports/open-payable-aging");
                  }
                }}
              />
            </WSGrid.Item>
            <WSGrid.Item span={{ s: "6", l: "5" }}>
              <WSCard
                data-testid="openLineItemAgingReport"
                title="Open Line Item Aging"
                content="Accounts payable aging summary of open line items grouped in either 7-day or 30-day due date increments."
                size="XS"
                primaryAction={{
                  label: "Open report",
                  onClick() {
                    history.push("/member/reports/open-line-item-aging");
                  }
                }}
              />
            </WSGrid.Item>
          </WSGrid>

          {featureFlags.data?.showArAgingReport && (
            <>
              <WSText.Heading4 mt="2XL" mb="XL">
                Receivable Reports
              </WSText.Heading4>
              <WSGrid>
                <WSGrid.Item span={{ s: "6", l: "5" }}>
                  <WSCard
                    data-testid="openReceivableAgingReport"
                    title="Open Receivable Aging"
                    content="Accounts receivable aging summary of open receivables grouped in either 7-day or 30-day due date increments."
                    size="XS"
                    primaryAction={{
                      label: "Open report",
                      onClick() {
                        history.push("/member/reports/open-receivable-aging");
                      }
                    }}
                  />
                </WSGrid.Item>
              </WSGrid>
            </>
          )}
        </>
      ) : null}
      {hasCollaboratorsScope ? (
        <>
          <WSText.Heading4 mt="2XL" mb="XL">
            Contractor Reports
          </WSText.Heading4>

          <WSGrid>
            <WSGrid.Item span={{ s: "6", l: "5" }}>
              <WSCard
                data-testid="collaboratorReport"
                title="Contractor"
                content="A list of contractors including personal details, onboarding status and group details."
                size="XS"
                primaryAction={{
                  label: "Open report",
                  onClick() {
                    history.push("/member/reports/collaborators");
                  }
                }}
              />
            </WSGrid.Item>
            <WSGrid.Item span={{ s: "6", l: "5" }}>
              <WSCard
                data-testid="collaboratorPayablesSummaryReport"
                title="Contractor Payables Summary"
                content="Summary of the total payments made to each contractor within a specified period."
                size="XS"
                primaryAction={{
                  label: "Open report",
                  onClick() {
                    history.push(
                      "/member/reports/collaborator-payables-summary"
                    );
                  }
                }}
              />
            </WSGrid.Item>
          </WSGrid>
        </>
      ) : null}
    </WSPage>
  );
};
