import { ReportsColumn } from "../../types";
// import { IReceivableAgingReportResponse } from "@wingspanhq/payments/dist/interfaces/api/reports";
import { formatReportsDate } from "../../utils/formatReportsDate";
import { formatReportMoney } from "../../utils/formatReportMoney";
import { IReceivableAgingReportResponse } from "./mocksTypes";

export const COLUMNS: ReportsColumn<IReceivableAgingReportResponse>[] = [
  {
    label: "Client ID",
    value: "Collaborator ID",
    getTableCell(data) {
      return data.clientId;
    }
  },
  {
    label: "Client Name",
    value: "Collaborator Name",
    preselected: true,
    getTableCell(data) {
      return data.clientName;
    }
  },
  {
    label: "Client Preferred Name",
    value: "Collaborator Preferred Name",
    getTableCell(data) {
      return data.clientPreferredName;
    }
  },
  {
    label: "Client Email",
    value: "Collaborator Email",
    getTableCell(data) {
      return data.clientEmail;
    }
  },
  {
    label: "Client Company",
    value: "Collaborator Company",
    getTableCell(data) {
      return data.clientCompany;
    }
  },
  {
    label: "Invoice Number",
    value: "Invoice Number",
    preselected: true,
    getTableCell(data) {
      return data.invoiceNumber;
    }
  },
  {
    label: "Invoice URL",
    value: "Invoice URL",
    getTableCell(data) {
      return `${window.location.host}/member/invoices/${data.invoiceId}`;
    }
  },
  {
    label: "Invoice PDF",
    value: "Invoice PDF",
    getTableCell(data) {
      return data.invoicePdf;
    }
  },
  {
    label: "Receipt PDF",
    value: "Receipt PDF",
    getTableCell(data) {
      return data.receiptPdf;
    }
  },
  {
    label: "Sent Date",
    value: "Sent Date",
    preselected: true,
    getTableCell(data) {
      return formatReportsDate(data.sentDate);
    }
  },
  {
    label: "Pay Date",
    value: "Pay Date",
    getTableCell(data) {
      return formatReportsDate(data.paidDate);
    }
  },
  {
    label: "Due Date",
    value: "Due Date",
    preselected: true,
    getTableCell(data) {
      return formatReportsDate(data.dueDate);
    }
  },
  {
    label: "Purchase Order Number",
    value: "Purchase Order Number",
    getTableCell(data) {
      return data.purchaseOrderNumber;
    }
  },
  {
    label: "Project Name",
    value: "Project Name",
    getTableCell(data) {
      return data.projectName;
    }
  },
  {
    label: "Notes",
    value: "Invoice Notes",
    getTableCell(data) {
      return data.notes;
    }
  },
  {
    label: "Invoice Amount",
    value: "Invoice Amount",
    preselected: true,
    getTableCell(data) {
      return formatReportMoney(data.amount);
    }
  },
  {
    label: "Age Group",
    value: "Age Group",
    preselected: true,
    disabled: true,
    getTableCell(data) {
      return data.ageGroup;
    }
  }
];
