import {
  RequirementContextType,
  RequirementTableCallback,
  RequirementTableRowConfig
} from "../../types";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { selectorIsRequirement } from "../../selectors/selectorIsRequirement";
import { usePayerSharedFileRequirementConfig } from "./cells/payer/usePayerSharedFileRequirementConfig";
import { usePayeeSharedFileRequirementConfig } from "./cells/payee/usePayeeSharedFileRequirementConfig";
import { useMemo } from "react";
import { concurrentActions } from "../../../../utils/serviceHelper";
import { getSignedDocument } from "../../../../services/signedDocuments";
import { queryCache } from "react-query";
import { QUERY_SIGNED_DOCUMENT } from "../../../../query/signedDocuments/keys";
import { ISignedDocument } from "@wingspanhq/signed-documents/dist/lib/interfaces";

export const useSharedFileRequirementTableConfig = (
  callback: RequirementTableCallback
): RequirementTableRowConfig[RequirementType.SharedFile] => {
  const payerSharedFileRequirementConfig = usePayerSharedFileRequirementConfig(
    callback
  );
  const payeeSharedFileRequirementConfig = usePayeeSharedFileRequirementConfig(
    callback
  );

  const cells = useMemo(() => {
    const allCells: Array<keyof Omit<
      RequirementTableRowConfig[RequirementType.SharedFile],
      "beforeRender"
    >> = ["nameCell", "typeCell", "statusCell", "rowActions", "rowMenuActions"];

    return allCells.reduce((acc, key) => {
      acc[key] = {
        [RequirementContextType.Payer]: requirement => {
          return payerSharedFileRequirementConfig[key](requirement) as any;
        },
        [RequirementContextType.Payee]: requirement => {
          return payeeSharedFileRequirementConfig[key](requirement) as any;
        }
      };
      return acc;
    }, {} as RequirementTableRowConfig[RequirementType.SharedFile]);
  }, []);

  return {
    ...cells,
    async beforeRender(requirements) {
      const actions = requirements
        .filter(selectorIsRequirement)
        .map(requirement => async () => {
          try {
            let document = queryCache.getQueryData<ISignedDocument>([
              QUERY_SIGNED_DOCUMENT,
              requirement.dataSourceId
            ]);

            if (!document) {
              document = await getSignedDocument(requirement.dataSourceId);
              queryCache.setQueryData(
                [QUERY_SIGNED_DOCUMENT, requirement.dataSourceId],
                document
              );
            }

            return document;
          } catch (e) {}
        });

      return await concurrentActions(actions, {
        concurrentLimit: 5
      });
    }
  };
};
