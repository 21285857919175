import {
  IMemberClientRequirementResponse,
  IRequirementResponse,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces";
import React from "react";
import {
  WSTableAction,
  WSTableRowMenuAction
} from "@wingspanhq/fe-component-library";
import { RequirementEvent } from "./utils";

export enum RequirementContextType {
  Payer = "Payer",
  Payee = "Payee"
}

export type RequirementTableItemData =
  | IMemberClientRequirementResponse
  | IRequirementResponse;

type DataItem<T extends RequirementType> = T extends RequirementType.Signature
  ? RequirementTableItemData
  : IRequirementResponse;

export type RenderCellFunction<T extends RequirementType> = (
  dataItem: DataItem<T>
) => React.ReactNode;

export type CellsByContext<T extends RequirementType> = {
  [keyof in RequirementContextType]: RenderCellFunction<T>;
};

export type RequirementCellsConfig<T extends RequirementType> = {
  nameCell: CellsByContext<T>;
  typeCell: CellsByContext<T>;
  statusCell: CellsByContext<T>;
  rowActions: {
    [keyof in RequirementContextType]: (
      dataItem: DataItem<T>
    ) => Array<WSTableAction>;
  };
  rowMenuActions: {
    [keyof in RequirementContextType]: (
      dataItem: DataItem<T>
    ) => WSTableRowMenuAction<DataItem<T>>[];
  };
  beforeRender?: (dataItems: DataItem<T>[]) => Promise<any>;
};

export type CellsConfig<
  R extends IMemberClientRequirementResponse | IRequirementResponse
> = {
  nameCell(props: R): React.ReactNode;
  typeCell(props: R): React.ReactNode;
  statusCell(props: R): React.ReactNode;
  rowActions(dataItem: R): WSTableAction[];
  rowMenuActions(dataItem: R): WSTableRowMenuAction<R>[];
};

export type RequirementTableRowConfig = {
  [K in RequirementType]: RequirementCellsConfig<K>;
};

export type RequirementTableCallback = (
  eventName: RequirementEvent,
  item: RequirementTableItemData
) => void;

export type BaseCreateRequirementDefinitionFormValues = {
  name: string;
  type: RequirementType;
  description?: string;
};

export type CreateRequirementDefinitionFormValues = BaseCreateRequirementDefinitionFormValues &
  CreateRequirementDefinitionSignaturePartialForm;

export type CreateRequirementDefinitionSignaturePartialForm = {
  eSignatureFile: File;
  requiredSignatures: "collaborator" | "mutual";
};
