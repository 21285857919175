import {
  useWSModal,
  WSButton,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSText
} from "@wingspanhq/fe-component-library";
import { usePaymentsStatusQuery } from "../../../../query/payments/queries";
import { openInNewTab } from "../../../../shared/utils/openInNewTab";
import { WS_LINKS } from "../../../../types/wsLinks";
import { BankStatements } from "../BankStatements";
import { useFeatureFlags } from "../../../../query/hooks/useFeatureFlags";
import { useHistory } from "react-router-dom";

export const WingspanAccounts: React.FC<WSElementProps> = props => {
  const featureFlags = useFeatureFlags();
  const history = useHistory();
  const queryPaymentsStatus = usePaymentsStatusQuery();
  const handleDepositAccountLimits = () => {
    const url = queryPaymentsStatus.data?.bankingAccountTier
      ? (WS_LINKS.depositLimit as any)[
          queryPaymentsStatus.data?.bankingAccountTier
        ]
      : undefined;
    openInNewTab(url ?? "https://wingspan.app");
  };

  const bankStatementsModal = useWSModal(BankStatements, {
    title: "Bank Statements"
  });

  return (
    <WSElement {...props}>
      <WSText weight="medium" mb="L">
        Wingspan Accounts
      </WSText>
      <WSElement colorBackground="gray50" p="2XL">
        <WSText color="gray500" mb="S">
          Wingspan manages a bank account on your behalf for accepting bank
          transfer payments and tax withholdings.
        </WSText>

        <WSFlexBox direction="column" style={{ gap: 12 }}>
          <WSButton.Link
            size="M"
            onClick={() => {
              if (featureFlags.data?.showNewStatements) {
                history.push("/member/statements");
              } else {
                bankStatementsModal.open({});
              }
            }}
          >
            View Statements
          </WSButton.Link>
          <WSButton.Link
            size="M"
            onClick={() =>
              openInNewTab(WS_LINKS.electronicDisclosureAndConsent)
            }
          >
            Electronic Consent Disclosure
          </WSButton.Link>
          <WSButton.Link
            size="M"
            onClick={() => openInNewTab(WS_LINKS.depositAccountTermsConditions)}
          >
            Deposit Account Terms
          </WSButton.Link>
          <WSButton.Link size="M" onClick={handleDepositAccountLimits}>
            Deposit Account Limits
          </WSButton.Link>
        </WSFlexBox>
      </WSElement>
    </WSElement>
  );
};
